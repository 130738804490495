'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import { GET_LEGACY_LOCATION } from '@deltasierra/frontend/graphql';
import { captureException } from '@sentry/nextjs';
import { useGetCookie } from 'cookies-next';
import { useEffect, useMemo } from 'react';

export const LEGACY_LOCATION_ID_COOKIE_KEY = 'plannerLocation';

export function useLegacyLocationIdFromCookie(redirectUrl: string): number | null {
    const getCookie = useGetCookie();
    const legacyLocationIdFromCookieRaw = getCookie(LEGACY_LOCATION_ID_COOKIE_KEY);

    const legacyLocationIdFromCookie = useMemo(() => {
        try {
            const id = legacyLocationIdFromCookieRaw ? parseInt(legacyLocationIdFromCookieRaw, 10) : null;
            return Number.isNaN(id) ? null : id;
        } catch (error) {
            captureException(error);
            return null;
        }
    }, [legacyLocationIdFromCookieRaw]);

    const { data } = useSuspenseQuery(
        GET_LEGACY_LOCATION,
        legacyLocationIdFromCookie
            ? {
                  variables: { legacyLocationId: legacyLocationIdFromCookie },
              }
            : skipToken,
    );

    // Redirect the user if the users cookie is set to a location they can't access
    useEffect(() => {
        if (legacyLocationIdFromCookie && !data) {
            window.location.replace(redirectUrl);
        }
    }, [data, legacyLocationIdFromCookie, redirectUrl]);

    return legacyLocationIdFromCookie;
}
