'use client';

import { useSetCookie } from 'cookies-next';
import { useEffect } from 'react';
import { LocationClientData } from '../types';
import { useAppConfig } from './useAppConfig';
import { useLocationDataFromURLParam } from './useLocationDataFromURLParam';
import { LEGACY_LOCATION_ID_COOKIE_KEY, useLegacyLocationIdFromCookie } from './useLegacyLocationIdFromCookie';

/**
 * Validates the location in the current URL params and then updates the cookie
 *
 * @param redirectUrl - on incorrect location id
 */
export function useGetLocationDataAndValidateLocationURLParam(redirectUrl: string): LocationClientData {
    const { appWebUrl } = useAppConfig();
    const setCookie = useSetCookie();

    const { locationData, locationIdFromParams } = useLocationDataFromURLParam();

    const legacyLocationIdFromCookie = useLegacyLocationIdFromCookie(redirectUrl);

    /**
     * This is for redirecting a user if the location that is supplied in the URL
     * is not available to this user.
     */
    useEffect(() => {
        if (!locationIdFromParams) {
            // There is no location param in this url so don't need to validate
            return;
        }
        if (!locationData) {
            // The location is not valid, redirect
            window.location.replace(redirectUrl);
        }
    }, [locationData, locationIdFromParams, redirectUrl]);

    /**
     * This is for updating the legacy app's cookie based on the selected location in the
     * URL search params.
     */
    useEffect(() => {
        if (locationData?.legacyLocationId && locationData.legacyLocationId !== legacyLocationIdFromCookie) {
            const baseUrlWithoutProtocol = new URL(appWebUrl).hostname;
            // There is a valid location, update the cookie
            setCookie(LEGACY_LOCATION_ID_COOKIE_KEY, `${locationData.legacyLocationId}`, {
                domain: `.${baseUrlWithoutProtocol}`,
                path: '/',
                secure: true,
            });
        }
    }, [appWebUrl, locationData, legacyLocationIdFromCookie, setCookie]);

    return (
        locationData ?? {
            clientId: null,
            locationId: null,
        }
    );
}
