'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
    VALIDATE_LOCATION,
    ValidateLocationQuery,
    ValidateLocationQueryVariables,
} from '@deltasierra/frontend/graphql';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

type LocationData = {
    clientId: string;
    legacyLocationId: number;
    locationId: string;
};

type UseLocationDataFromURLParamResult = {
    locationIdFromParams: string | null;
    locationData: LocationData | null;
};

export function useLocationDataFromURLParam(): UseLocationDataFromURLParamResult {
    const searchParams = useSearchParams();

    const locationIdFromParams = searchParams.get(QueryParameter.LocationId);
    const { data } = useSuspenseQuery<ValidateLocationQuery, ValidateLocationQueryVariables>(
        VALIDATE_LOCATION,
        locationIdFromParams
            ? {
                  variables: { id: locationIdFromParams },
              }
            : skipToken,
    );

    const locationData = useMemo(
        () =>
            data?.location
                ? {
                      clientId: data.location.client.id,
                      legacyLocationId: data.location.legacyId,
                      locationId: data.location.id,
                  }
                : null,
        [data],
    );

    return {
        locationData,
        locationIdFromParams,
    };
}
